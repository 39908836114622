import React from "react";
import { Card, CardContent, ListItem, ListItemAvatar } from "@mui/material";
import CountUp from "react-countup";
import "../../../Admin/OverAllStats/index.css";

export const StatsCard = ({ data, showTypes, showTypes2 }) => {
  const renderTopExpert = () => {
    const { expertHeading, icon, junkeeCounts } = data;
    return (
      <Card sx={{ maxWidth: 310, boxShadow: "none", margin: "auto" }}>
        <CardContent style={{ backgroundColor: "#f7f6fe", height: 300 }}>
          <div>
            <div className="experts_heading">
              <h3>{expertHeading}</h3>
            </div>

            <div className="icon">
              {icon}
              <br />
              <span style={{ fontSize: 36 }}>
                <CountUp duration={0.6} end={junkeeCounts} />
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  const renderTopCategories = () => {
    const { heading, categories } = data;

    return (
      <Card sx={{ maxWidth: 310, boxShadow: "none", margin: "auto" }}>
        <CardContent style={{ backgroundColor: "#f7f6fe", height: 300 }}>
          <div className="experts_heading">
            <h3>{heading}</h3>
          </div>
          <div sx={{ width: "100%", maxWidth: 360 }}>
            {categories?.map((category) => (
              <ListItem key={category.title}>
                <ListItemAvatar className="top_cateogory_icon">
                  {" "}
                  {category.icon}
                </ListItemAvatar>
                <p className="top_cateogory_text">{category.title}</p>
              </ListItem>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      {showTypes && renderTopExpert()}
      {showTypes2 && renderTopCategories()}
    </>
  );
};
