import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import BackButton from "../../assets/icons/BackButton.svg";
import Logout from "../../assets/icons/logoutImg.svg";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import "./index.css";
import { getUserDetails } from "../../actions/userData";
import { useParams } from "react-router-dom";

const UserDetails = () => {
  const userDetails = useSelector((state) => state.userData.userDetail);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [userCategoryWiseAnswer, setUserCategoryWiseAnswer] = useState(null);
  React.useEffect(() => {
    dispatch(getUserDetails(id));
  }, [userCategoryWiseAnswer]);

  const age = userDetails && userDetails.user && userDetails.user[0].age;
  if (userDetails && userDetails.expercategory && userDetails.usercategory) {
    const newArr = [...userDetails.expercategory, ...userDetails.usercategory];

    const data = newArr.reduce((dict, data) => {
      if (!dict[data.id]) dict[data.id] = [];
      dict[data.id].push(data);
      return dict;
    }, {});

    if (!userCategoryWiseAnswer) {
      setUserCategoryWiseAnswer(data);
    }
  }

  let history = useHistory();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#F7F6FE",
      borderTop: "2px solid white",
      borderBottom: "2px solid white",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleLogout = () => {
    window.localStorage.clear();
    history.push("/");
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} md={4}>
          <p className="admin">WELCOME, ADMIN!</p>
          <br />
        </Grid>

        <Grid item xs={4} md={4}></Grid>
        <Grid item xs={4} md={4}>
          <div className="logout">
            <p>LOGOUT</p>
            <img
              onClick={() => handleLogout()}
              src={Logout}
              className="logout_Svg"
              alt="logout"
            />
          </div>{" "}
        </Grid>
        <Grid item xs={4} md={3}>
          <div className="back_btn">
            <Link to="/Dashboard">
              <img src={BackButton} alt="btn" />
            </Link>
          </div>
        </Grid>
        <Grid item xs={6} md={6}>
          <div className="user_name"></div>
        </Grid>
      </Grid>{" "}
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: 9 }}
      >
        <Grid item xs={12} md={9}>
          <Table sx={{ minWidth: 120 }} aria-label="customized table">
            <TableHead>
              <div className="details">Details</div>
              <TableRow>
                <StyledTableCell>
                  <tr className="table_row">Username</tr>{" "}
                </StyledTableCell>
                <StyledTableCell>
                  <tr className="table_row">Gender </tr>
                </StyledTableCell>
                <StyledTableCell>
                  <tr className="table_row">Age&nbsp;</tr>
                </StyledTableCell>
                <StyledTableCell>
                  <tr className="table_row">Location</tr>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>
                  <td className="table_data">
                    {userDetails &&
                      userDetails.user &&
                      userDetails.user[0].username}
                  </td>
                </StyledTableCell>
                <StyledTableCell>
                  <td className="table_data">
                    {userDetails &&
                      userDetails.user &&
                      userDetails.user[0].gender}{" "}
                  </td>
                </StyledTableCell>
                <StyledTableCell>
                  <td className="table_data">
                    {age > 100 || age < 0 ? "" : age}
                  </td>
                </StyledTableCell>
                <StyledTableCell>
                  <td className="table_data">
                    {userDetails &&
                      userDetails.user &&
                      userDetails.user[0].location}{" "}
                  </td>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Grid>{" "}
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} md={9} sx={{ marginBottom: 12 }}>
            <Table sx={{ minWidth: 120 }} aria-label="customized table">
              <TableHead>
                <div className="Expertise">Areas of Expertise</div>
                <TableRow>
                  <StyledTableCell>
                    <tr className="table_row">Category</tr>{" "}
                  </StyledTableCell>
                  {/*<StyledTableCell>
                    <tr className="table_row">Total # Question </tr>
                  </StyledTableCell>*/}
                  <StyledTableCell>
                    <tr className="table_row">Total # Answers&nbsp;</tr>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userCategoryWiseAnswer &&
                  Object.values(userCategoryWiseAnswer).map((category) => {
                    let correctAnswerCount = 0;
                    let correctQuestionCount = 0;
                    let totalAnswer = 0;
                    category.map((data) => {
                      if (data.postOption) {
                        totalAnswer++;
                      }
                      {
                      }
                    });
                    return (
                      <StyledTableRow>
                        <StyledTableCell>
                          <td className="table_data">
                            {category[0].icon} {category[0].title}
                          </td>
                        </StyledTableCell>

                        <StyledTableCell>
                          <td className="table_data"> {totalAnswer}</td>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default UserDetails;
